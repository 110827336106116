import {SET_SEARCH_INDEX} from '../actions.js';

function searchReducer (prevState = {}, action) {
    switch (action.type) {
        case SET_SEARCH_INDEX:
            return {
                ...prevState,
                index: action.index
            }
        default:
            return prevState
    }
}

export default searchReducer;
