import React from 'react';
import { Link } from "@reach/router";
import { ListItem, ListItemText, Chip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import TransliteratedText from './TransliteratedText';

function getDisplayText(doc, matches) {
    const content = doc.content;

    // Get highlights
    let allHighlights = [];

    for (const i in matches) {
        for (const enc1 in matches[i]) {
            allHighlights.push(...matches[i][enc1]);
        }
    }

    allHighlights.sort((a, b) => a.tokenIndex - b.tokenIndex);
    allHighlights = allHighlights.map(({charIndex, tokenIndex, token, queryToken}) => ({
        charIndex,
        tokenIndex,
        token,
        matches: {
            [queryToken.index]: queryToken.similarity
        },
    }))

    allHighlights = allHighlights.reduce((uniqueHighlights, highlight) => {
        if (uniqueHighlights.length && uniqueHighlights[uniqueHighlights.length - 1].tokenIndex === highlight.tokenIndex) {
            let lastHighlight = uniqueHighlights[uniqueHighlights.length - 1];

            console.log(highlight.matches);

            for (let [i, similarity] of Object.entries(highlight.matches)) {
                lastHighlight.matches[i] = Math.max(similarity, lastHighlight.matches[i] || 0);
            }
        } else {
            uniqueHighlights.push(highlight);
        }

        return uniqueHighlights;
    }, []);

    // Get lines
    let lines = [];
    let start = 0;
    // eslint-disable-next-line no-control-regex
    for (const match of content.matchAll(/(\u{A}|\u{B}|\u{C}|\u{D}|\u{85}|\u{2028}|\u{2029}|\u{D}\u{A})/gu)) {
        lines.push({
            text: content.substring(start, match.index),
            start,
        })
        start = match.index + match[0].length;
    }

    // Get scores and highlights for lines
    let highlightIndex = 0;
    for (const line of lines) {
        const start = line.start;
        const end = line.start + line.text.length;

        let score = {
            matches: {},
            similarity: 0,
        };
        let highlights = []

        for (; highlightIndex < allHighlights.length && allHighlights[highlightIndex].charIndex < end; ++highlightIndex) {
            let highlight = allHighlights[highlightIndex];
            if (highlight.charIndex >= start) { // This should always be true
                Object.entries(highlight.matches).forEach(([i, similarity]) => {
                    score.matches[i] = Math.max(similarity, score.matches[i] || 0);
                })
                highlights.push({
                    start: highlight.charIndex,
                    end: highlight.charIndex + highlight.token.length,
                })
            }
        }

        line.score = score;
        line.highlights = highlights;
    }

    // Select lines to show
    let summaryLines = new Set();
    let linesLeft = lines.map((_, i) => i);
    for (let summaryLength = 0; summaryLength < 3; ++summaryLength) {
        let lineScores = linesLeft.map((newLine) => {
            let newSummary = [...summaryLines, newLine];
            let matches = {};
            let count = 0;
            newSummary.forEach((summaryLine) => {
                Object.entries(lines[summaryLine].score.matches).forEach(([i, similarity]) => {
                    matches[i] = Math.max(similarity, matches[i] || 0);
                });
                count += lines[summaryLine].highlights.length;
            });
            const coverage = Object.values(matches).reduce((coverage, similarity) => coverage + similarity, 0);

            return coverage * 1000 + count;
        })

        let maxScore = Math.max(...lineScores);
        for (const [i, score] of lineScores.entries()) {
            if (score === maxScore) {
                let line = linesLeft[i];
                summaryLines.add(line);
                linesLeft = linesLeft.filter(i => i !== line);
                break;
            }
        }
    }

    // Prepare lines for showing
    let summary = [];
    let lastAdded = -1;
    for (const i of [...summaryLines].sort((a, b) => a - b)) {
        if (i - lastAdded === 2) {
            summary.push(lines[i - 1]);
        } else if (i - lastAdded > 2) {
            summary.push({
                text: "…"
            });
        }

        summary.push(lines[i]);
        lastAdded = i;
    }
    if (lastAdded !== lines.length - 1) {
        summary.push({
            text: "…",
        });
    }

    // Highlight
    let key = 0;
    let summaryChunks = summary.map((line) => {
        let chunks = [];
        let lastChar = 0;
        if (line.highlights && line.highlights.length) {
            for (const highlight of line.highlights) {
                const start = highlight.start - line.start;
                const end = highlight.end - line.start;

                if (lastChar < start) {
                    chunks.push(<TransliteratedText from={doc.script} key={key++}>{line.text.substring(lastChar, start)}</TransliteratedText>);
                }
                chunks.push(<strong key={key++}><TransliteratedText from={doc.script} key={key++}>{line.text.substring(start, end)}</TransliteratedText></strong>);
                lastChar = end;
            }
        }
        if (lastChar < line.text.length) {
            chunks.push(<TransliteratedText from={doc.script} key={key++}>{line.text.substring(lastChar, line.text.length)}</TransliteratedText>);
        }
        return chunks;
    }).reduce((summaryChunks, lineChunks) => {
        if (summaryChunks.length) {
            summaryChunks.push(<br key={key++}/>);
        }
        summaryChunks.push(...lineChunks);
        return summaryChunks;
    }, []);

    return (
        <pre>
            {summaryChunks}
        </pre>
    );
}

function SearchResult(props) {
    const theme = useTheme();

    return (
        <ListItem button component={Link} to={props.path} style={{...props.style, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: theme.palette.divider, overflow: "hidden"}}>
            <ListItemText>
                <TransliteratedText from={props.doc.script}>{props.doc.title}</TransliteratedText>
                <ListItemText>
                    {getDisplayText(props.doc, props.result.matches)}
                </ListItemText>
                <Chip label={props.collection} />
            </ListItemText>
        </ListItem>
    )
}

export default SearchResult;