import React from 'react';
import { connect } from 'react-redux';
import { Link } from "@reach/router";
import { Container, Typography, Box, ListItem, ListItemText, ListItemIcon, ListSubheader } from '@material-ui/core';
import { LibraryBooks } from '@material-ui/icons';
import GoogleAnalytics from './GoogleAnalytics';
import TransliteratedText from './TransliteratedText';

function CollectionList(props) {
    return (
        <Container>
            <GoogleAnalytics uri={props.uri} title={"Soor-Shabda"} />
            <Box paddingTop={2}>
                <ListSubheader component="div">
                    <Typography variant="h4" component="h2">Collections</Typography>
                </ListSubheader>
            </Box>
            {
                Object.entries(props.collections).map(([collectionId, collection]) => (
                    <ListItem button component={Link} to={`/c/${collectionId}`} key={collectionId}>
                        <ListItemIcon>
                            <LibraryBooks />
                        </ListItemIcon>
                        <ListItemText secondary={
                            `${Object.keys(collection.docs).length} items`
                        } >
                            <TransliteratedText>{collection.title}</TransliteratedText>
                        </ListItemText>
                    </ListItem>
                ))
            }
        </Container>
    );
}

export default connect(
    (state) => ({collections: state.collections})
)(CollectionList);