import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { detect, transliterate } from 'lipi';

const options = {
    qaab: {
        disableDisambiguation: true,
        deleteFinalSchwa: true,
        useCHandCHH: true,
        autoConvertAnuswaraToChandrabindu: true,
    }
}

function TransliteratedText(props) {
    let from = props.from;
    let to = props.to;

    if (!from) {
        from = detect(props.children);
    }

    if (!to) {
        to = props.scripts.indexOf(from) >= 0 ? from : props.scripts[0];
    }

    return transliterate(props.children, from, to, options);
}

TransliteratedText.propTypes = {
    from: PropTypes.oneOf(['qaaa', 'gujr', 'deva']),
    to: PropTypes.oneOf(['qaaa', 'gujr', 'deva', 'qaab']),
    children: PropTypes.string.isRequired
}

export default connect(
    (state) => ({scripts: state.settings.scripts})
)(TransliteratedText);
