import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { Container, ListItem, ListItemText, Zoom, Fab } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';
import TransliteratedText from './TransliteratedText';
import NotFound from './NotFound';

function DocumentListItem(props) {
    const theme = useTheme();
    if (props.index >= props.data.length) {
        // Bottom padding item
        return (
            <ListItem component="div" key={`padding-${props.index}`} style={{...props.style}}>
            </ListItem>
        );
    }

    const item = props.data[props.index];

    return (
        <ListItem button component={Link} to={item.id} key={props.index} style={{...props.style, borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: theme.palette.divider}}>
            <ListItemText>
                <TransliteratedText from={item.script}>{item.title}</TransliteratedText>
            </ListItemText>
            {/* <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="Favourite">
                    <StarBorder />
                </IconButton>
            </ListItemSecondaryAction> */}
        </ListItem>
    );
}

function ContainerRelative(props) {
    return (
        <Container style={{...props.style, position: "relative"}}>
            {props.children}
        </Container>
    )
}

function VirtualList(props) {
    const [showScrollTop, setShowScrollTop] = useState(false);
    const listRef = useRef();

    return (
        <>
        <AutoSizer>
            {({height, width }) => {
                return (
                    <FixedSizeList
                        height={height}
                        itemCount={props.itemData.length + 3 /* padding */}
                        itemData={props.itemData}
                        itemSize={46}
                        width={width}
                        innerElementType={ContainerRelative}
                        overscanCount={5}
                        style={{overscrollBehavior: "contain"}}
                        onScroll={({scrollOffset}) => {
                            setShowScrollTop(scrollOffset > (height / 2));
                        }}
                        ref={listRef}
                        >
                        {DocumentListItem}
                    </FixedSizeList>
                )
            }}
        </AutoSizer>
        <ScrollTopFab shown={showScrollTop} onClick={() => listRef.current.scrollTo(0)} />
        </>
    )
}

function ScrollTopFab(props) {
    const theme = useTheme();

    return (
        <Zoom in={props.shown}>
            <Fab
                color="secondary"
                style={{position: 'fixed', right: theme.spacing(4), bottom: theme.spacing(4)}}
                onClick={props.onClick}
                aria-label="Scroll to Top"
            >
                <ArrowUpward />
            </Fab>
        </Zoom>
    );
}

class Collection extends React.Component {
    getItemData(collection) {
        return Object.keys(collection.docs).map((id) => ({
            ...collection.docs[id],
            id
        })).sort((a, b) => {
            return a.devaTitle.localeCompare(b.devaTitle)
        })
    }

    render() {
        const collection = this.props.collections[this.props.collectionId];

        if (!collection) return <NotFound />;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{collection.title}</title>
                </Helmet>
                <GoogleAnalytics uri={this.props.uri} title={collection.title} />
                <VirtualList itemData={this.getItemData(collection)} />
            </React.Fragment>
        );
    }
}

export default connect(
    (state) => ({collections: state.collections})
)(Collection);