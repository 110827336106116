import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, Box, List, Divider, CircularProgress } from '@material-ui/core';
import SearchResult from './SearchResult';
import GoogleAnalytics, { logUserTiming, logEvent } from './GoogleAnalytics';
import { setSearchIndex } from '../store/actions';

import { AdminInterface } from './Settings';
import { setAdminIterface } from '../store/actions';

import SearchIndic from '../search';

const SearchSettingsMiddleware = connect(
    (state) => ({settings: state.settings})
)((props) => {
    let hotword = props.searchBoxText.toLowerCase();
    let showAdmin = hotword === "iamadmin" || hotword === "makemeadmin";

    return showAdmin && (
        <>
            <AdminInterface checked={props.settings.admin} onChange={(event) => props.dispatch(setAdminIterface(event.target.checked))} />
            <Divider />
        </>
    )
});

function Search(props) {
    const searchIndic = new SearchIndic();
    const { collections, dispatch } = props;
    const { searchBoxRef, searchBoxText } = props;

    // Import if index exists
    if ("index" in props.search) {
        searchIndic.import(props.search.index);
    }

    const indexLoaded = !!searchIndic.invertedIndexMetadata.countDocuments;

    // Index if not already exists
    useEffect(() => {
            if (searchIndic.invertedIndexMetadata.countDocuments) return;

            const startTime = new Date();

            // Index
            for (const collection in collections) {
                for (const doc in collections[collection].docs) {
                    const content = collections[collection].docs[doc].content;
                    const docId = `${collection}/${doc}`;

                    searchIndic.addDocument(docId, content);
                }
            }

            dispatch(setSearchIndex(searchIndic.export()));

            console.log(searchIndic.invertedIndexMetadata);
            console.log("Indexing done in: ", new Date() - startTime, "ms");
            logUserTiming("Search", "indexing", Math.round(new Date() - startTime));
        },
        []  // eslint-disable-line react-hooks/exhaustive-deps
    );


    // Focus blur searchbox
    useEffect(() => {
        const searchBox = searchBoxRef.current;

        searchBox.focus();
        searchBox.setSelectionRange(0, searchBox.value.length);

        return () => {
            searchBox.blur();
        }
    }, [searchBoxRef])


    // Search and render
    let startTime = new Date();
    let results = searchIndic.search(searchBoxText) || [];

    // Limit to 10 results
    results = results.slice(0, 10);

    if (searchBoxText) {
        console.log("Fetched results in: ", new Date() - startTime, "ms");
        logEvent("search");
        logUserTiming("Search", "search", Math.round(new Date() - startTime));
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Search</title>
            </Helmet>
            <GoogleAnalytics uri={props.uri} title={"Search"} />
            <SearchSettingsMiddleware searchBoxText={searchBoxText} />
            {
                (!results.length || !indexLoaded) && (
                    <Container>
                        <Box paddingY={2}>
                            {!indexLoaded && (
                                <Box paddingTop={2} textAlign="center">
                                    <CircularProgress disableShrink color="secondary" />
                                </Box>
                            )}
                            {indexLoaded && (searchBoxText ?
                                "No results found. Try something else?" :
                                "Search by typing in the box above.")}
                        </Box>
                    </Container>
                )
            }
            {
                !!results.length && (
                    <List>
                    {
                        results.map((result) => (
                            <SearchResult
                                path={`/c/${result.docId}`}
                                key={`/c/${result.docId}`}
                                doc={collections[result.docId.split("/")[0]].docs[result.docId.split("/")[1]]}
                                collection={collections[result.docId.split("/")[0]]?.title}
                                result={result.result}
                            />
                        ))
                    }
                    </List>
                )
            }
        </React.Fragment>
    )
}

export default connect(
    (state) => ({collections: state.collections, search: state.search, settings: state.settings})
)(Search);
