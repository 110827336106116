import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { PersistGate } from 'redux-persist/integration/react';

import { setServiceWorkerUpdateAvailable } from './store/actions';

let {store, persistor} = configureStore();

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.register({
    onUpdate: (registration) => {
        store.dispatch(setServiceWorkerUpdateAvailable(registration.waiting));
    },
});
