import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Router, useNavigate, useMatch } from '@reach/router';
import { AppBar as MaterialAppBar, Toolbar, IconButton, Typography, SwipeableDrawer, Fade, InputBase, Tooltip, makeStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import BackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import { EditOutlined, GetApp } from '@material-ui/icons'
import AppDrawerContent from './AppDrawerContent'
import TransliteratedText from './TransliteratedText';
import config from '../config';

import { promptInstall } from '../store/actions';

const useStyles = makeStyles({
    label: {
       padding: "inherit"
    }
});

function LeftButton(props) {
    const classes = useStyles();
    const label = props.icon === "drawer" ? "Open Menu" : props.icon === "back" ? "Back" : false;

    return (
        <IconButton edge="start" color="inherit" classes={{label: classes.label}} onClick={props.onClick} aria-label={label} >
            <Fade in={props.icon === "drawer"} timeout={300} mountOnEnter unmountOnExit>
                <MenuIcon style={{position: "absolute"}} />
            </Fade>
            <Fade in={props.icon === "back"} timeout={300} mountOnEnter unmountOnExit>
                <BackIcon style={{position: "absolute"}} />
            </Fade>
        </IconButton>
    )
}

/**
 * props:
 * - openDrawer: Callback to open drawer.
 * - searchBoxText
 * - onSearchBoxTextChange
 */
function AppToolbar(props) {
    const navigate = useNavigate();

    const matchCollection = useMatch("/c/:collectionId");
    const matchDocument = useMatch("/c/:collectionId/:documentId");
    const matchSettings = useMatch("/settings");
    const matchSearch = useMatch("/search");

    let isValidCollectionOrDocument = false;
    let collectionTitle;
    if (matchCollection) {
        isValidCollectionOrDocument = matchCollection.collectionId in props.collections;
        collectionTitle = props.collections[matchCollection.collectionId]?.title;
    } else if (matchDocument) {
        isValidCollectionOrDocument = (matchDocument.collectionId in props.collections) && (matchDocument.documentId in props.collections[matchDocument.collectionId].docs);
        collectionTitle = props.collections[matchDocument.collectionId]?.title;
    }

    const toolbarProps = {
        search: matchSearch ? true : false,
        TitleElement: isValidCollectionOrDocument ? TransliteratedText : React.Fragment,
        title: isValidCollectionOrDocument ? collectionTitle : matchSettings ? "Settings" : "Soor-Shabda",
        leftButton: {
            onClick: matchSearch || matchSettings ? () => navigate(-1) : matchDocument ? () => props.navigate("..") : props.openDrawer,
            icon: matchSearch || matchSettings || matchDocument ? "back" : "drawer"
        },
        edit: isValidCollectionOrDocument && matchDocument && props.settings.admin ? config.getEditUrl(matchDocument.collectionId, matchDocument.documentId) : false,
        install: !!props.serviceWorker.beforeinstallprompt
    }

    return (
        <Toolbar>
            <LeftButton {...toolbarProps.leftButton} />
            <InputBase
                placeholder="Search"
                autoFocus={toolbarProps.search}
                inputProps={{"aria-label": "Search", tabIndex: toolbarProps.search ? 0 : -1}}
                type="search"
                style={{color: "inherit", userSelect: "auto", width: toolbarProps.search ? "100%" : "0px"}}
                inputRef={props.searchBoxRef}
                value={props.searchBoxText}
                disabled={!toolbarProps.search}
                onChange={(event) => props.onSearchBoxTextChange(event.target.value)}
            />
            <Typography variant="h6" noWrap style={{flex: 1, ...(toolbarProps.search ? {display: "none"} : {})}} >
                <toolbarProps.TitleElement>{toolbarProps.title}</toolbarProps.TitleElement>
            </Typography>
            { toolbarProps.edit && (
                <Tooltip title="Edit">
                    <IconButton
                        color="inherit"
                        style={(toolbarProps.search ? {display: "none"} : {})}
                        component="a" href={toolbarProps.edit} target="_blank"
                    >
                        <EditOutlined />
                    </IconButton>
                </Tooltip>
            )}
            { toolbarProps.install && (
                <Tooltip title="Install App">
                    <IconButton
                        color="inherit"
                        style={(toolbarProps.search ? {display: "none"} : {})}
                        onClick={() => {
                            props.dispatch(promptInstall());
                        }}
                    >
                        <GetApp />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Search">
                <IconButton
                    edge="end" color="inherit"
                    component={Link} to="/search"
                    style={(toolbarProps.search ? {display: "none"} : {})}
                >
                    <SearchIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    )
}

function AppBar(props) {
    let [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <React.Fragment>
            <SwipeableDrawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)} onOpen={() => setDrawerOpen(true)}>
                <AppDrawerContent closeDrawer={() => setDrawerOpen(false)} />
            </SwipeableDrawer>
            <MaterialAppBar position="sticky">
                <Router primary={false}>
                    <AppToolbar default openDrawer={() => setDrawerOpen(true)} {...props} />
                </Router>
            </MaterialAppBar>
        </React.Fragment>
    );
}

export default connect(
    (state) => ({collections: state.collections, settings: state.settings, serviceWorker: state.serviceWorker})
)(AppBar);