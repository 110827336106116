import React, { useState, useRef } from 'react';
import { Router, Redirect } from "@reach/router";
import { Helmet } from 'react-helmet';

import AppBar from './AppBar';
import ServiceWorkerHelper from './ServiceWorkerHelper';
import CollectionList from './CollectionList';
import Collection from './Collection';
import Document from './Document';
import Settings from './Settings';
import Search from './Search';
import NotFound from './NotFound';
import { Box, CssBaseline, useMediaQuery } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

function App(props) {
    const searchBoxRef = useRef(null);
    let [searchBoxText, setSearchBoxText] = useState("");

    return (
        <Box display="flex" flexDirection="column" height="100vh" width="100vw" overflow="hidden" style={{userSelect: "none"}}>
            <CssBaseline />
            <Helmet titleTemplate="%s • Soor-Shabda" defaultTitle="Soor-Shabda" />
            <ServiceWorkerHelper />
            <AppBar searchBoxText={searchBoxText} onSearchBoxTextChange={setSearchBoxText} searchBoxRef={searchBoxRef} />
            <Router primary={false} style={{flex: 1, overflow: "auto"}}>
                <Redirect from="c" to="/" noThrow />
                <Collection path="c/:collectionId" />
                <Document path="c/:collectionId/:documentId" />
                <CollectionList path="/" />
                <Settings path="/settings" />
                <Search path="/search" searchBoxText={searchBoxText} searchBoxRef={searchBoxRef} />
                <NotFound default />
            </Router>
        </Box>
    );
}

function ThemedApp(props) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    primary: {
                        main: "#573f61",
                    },
                    secondary: {
                        main: "#0d827b",
                    }
                },
            }),
        [prefersDarkMode],
    );

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <meta name="theme-color" content={theme.palette.primary.dark} />
            </Helmet>
            <App {...props} />
        </ThemeProvider>
    );
}

export default ThemedApp;
