import { useEffect } from 'react';

function GoogleAnalytics({uri, title}) {
    useEffect(() => {
        if (typeof window !== "undefined" && window.gtag && window.GA_MEASUREMENT_ID) {
            window.gtag('config', window.GA_MEASUREMENT_ID, {'page_path': uri, 'page_title': title});
        }
    }, [uri, title])

    return null;
}

export function logUserTiming(category, name, timing) {
    if (typeof window !== "undefined" && window.gtag && window.GA_MEASUREMENT_ID) {
        window.gtag('event', 'timing_complete', {
            'name': name,
            'value': timing,
            'event_category': category,
        });
    }
}

export function logEvent(event_name, event_data) {
    if (typeof window !== "undefined" && window.gtag && window.GA_MEASUREMENT_ID) {
        window.gtag('event', event_name, event_data);
    }
}

export default GoogleAnalytics;
