const latinMap = {
    // Vowels
    a: {target: "", exclusive: false, combine: false},
    aa: {target: "a", exclusive: true, combine: false},
    i: {target: "i", exclusive: false, combine: false},
    ii: {target: "i", exclusive: true, combine: false},
    ee: {target: "i", exclusive: true, combine: false},
    u: {target: "u", exclusive: false, combine: false},
    oo: {target: "u", exclusive: true, combine: false},
    e: {target: "e", exclusive: false, combine: false},
    ai: {target: "e", exclusive: false, combine: false},
    ei: {target: "e", exclusive: false, combine: false},
    o: {target: "o", exclusive: false, combine: false},
    au: {target: "o", exclusive: false, combine: false},
    ou: {target: "o", exclusive: false, combine: false},
    ru: {target: "Ru", exclusive: false, combine: true, score: .5},
    ri: {target: "Ru", exclusive: false, combine: true, score: .5},
    // Consonants
    k: {target: "k", exclusive: true, combine: true},
    q: {target: "k", exclusive: true, combine: true},
    kh: {target: "k`", exclusive: true, combine: true},
    g: {target: "g", exclusive: true, combine: true},
    gh: {target: "g`", exclusive: true, combine: true},
    c: {target: "k", exclusive: true, combine: true},
    ch: {target: "c", exclusive: true, combine: true},
    chh: {target: "C", exclusive: true, combine: true},
    j: {target: "j", exclusive: true, combine: true},
    jh: {target: "j`", exclusive: true, combine: true},
    z: {target: "j`", exclusive: true, combine: true},
    t: {target: "t", exclusive: true, combine: true},
    th: {target: "t`", exclusive: true, combine: true},
    d: {target: "d", exclusive: true, combine: true},
    dh: {target: "d`", exclusive: true, combine: true},
    n: {target: "n", exclusive: true, combine: true},
    p: {target: "p", exclusive: true, combine: true},
    ph: {target: "p`", exclusive: true, combine: true},
    f: {target: "p`", exclusive: true, combine: true},
    b: {target: "b", exclusive: true, combine: true},
    bh: {target: "b`", exclusive: true, combine: true},
    m: {target: "m", exclusive: true, combine: true},
    y: {target: "y", exclusive: true, combine: true},
    r: {target: "r", exclusive: true, combine: true},
    l: {target: "l", exclusive: true, combine: true},
    v: {target: "v", exclusive: true, combine: true},
    w: {target: "v", exclusive: true, combine: true},
    s: {target: "s", exclusive: true, combine: true},
    sh: {target: "S", exclusive: true, combine: true},
    h: {target: "h", exclusive: true, combine: true},
    gn: {target: "jfn", exclusive: false, combine: false},
    gy: {target: "jfn", exclusive: false, combine: false},
    x: {target: "kS", exclusive: false, combine: true},
}

function encodeLatinCore(str, isBeginning = true) {
    const maxSeqLength = Math.max(...Object.keys(latinMap).map((a) => a.length));

    const results = [];

    for (let len = Math.min(maxSeqLength, str.length); len > 0; --len) {
        const chunk = str.substring(0, len);
        const rest = str.substring(len);

        if (!(chunk in latinMap)) continue;

        let encodedRest = [{
            target: "",
            combine: false,
            score: 1,
        }];

        if (rest) {
            encodedRest = encodeLatinCore(rest, false);
        }

        for (let enc of encodedRest) {
            const combiningChar = enc.combine && latinMap[chunk].combine ? "f" : "";

            // Handle `a` at the beginning
            let target = latinMap[chunk].target;
            if (isBeginning && target === "") {
                target = "a";
            }

            results.push({
                target: target + combiningChar + enc.target,
                combine: latinMap[chunk].combine,
                score: enc.score * (latinMap[chunk].score || 1)
            });

            // Handle anuswara
            if ((target === "m" || target === "n")
                && (combiningChar === "f" || !rest))
            {
                results.push({
                    target: "q" + enc.target,
                    combine: latinMap[chunk].combine,
                    score: enc.score * (latinMap[chunk].score || 1)
                });
            }
        }

        if (latinMap[chunk].exclusive) break;
    }

    return results;
}

export function encodeLatinForSearch(str) {
    str = str.normalize().toLowerCase();

    const encodings = encodeLatinCore(str).map(({target, score}) => {
        // 1st encoding, see encodeIndic for more details
        let encoding1 = target;

        // 2nd encoding, see encodeIndic for more details
        let encoding2 = "I" + encoding1
            .toLowerCase()
            .replace(/f/g, "")  // Remove halant
            .replace(/q/g, "")  // Remove anuswara
            .replace(/([^^])a+/g, "$1");  // Remove a in the middle

        return {
            enc: [encoding1, encoding2],
            score,
            similarityMethod: "latin",
        }
    });

    encodings.push({
        enc: [str, "O" + str],
        score: 1,
        similarityMethod: "latin",
    });

    return encodings;
}

export function encodeLatinForIndex(str) {
    str = str.normalize().toLowerCase();

    return [str, "O" + str];
}
