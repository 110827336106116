// -------------------
// Settings
export const SET_PREFERRED_SCRIPTS = "SET_PREFERRED_SCRIPTS";
export const SET_ADMIN_INTERFACE = "SET_ADMIN_INTERFACE";

export function setPreferredScripts(scripts) {
    return {
        type: SET_PREFERRED_SCRIPTS,
        scripts
    }
}

export function setAdminIterface(isAdmin) {
    return {
        type: SET_ADMIN_INTERFACE,
        admin: isAdmin
    }
}


// -------------------
// Search
export const SET_SEARCH_INDEX = "SET_SEARCH_INDEX";

export function setSearchIndex(exportedIndex) {
    return {
        type: SET_SEARCH_INDEX,
        index: exportedIndex
    }
}


// -------------------
// ServiceWorker
export const SET_WAITING_SERVICE_WORKER = "SET_WAITING_SERVICE_WORKER";
export const SET_INSTALL_PROMPT_EVENT = "SET_INSTALL_PROMPT_EVENT";

export function setServiceWorkerUpdateAvailable(waitingWorker) {
    return {
        type: SET_WAITING_SERVICE_WORKER,
        waitingWorker: waitingWorker
    }
}

export function updateServiceWorker() {
    return function(dispatch, getState) {
        const waitingWorker = getState().serviceWorker.waitingWorker;

        if (waitingWorker) {
            waitingWorker.addEventListener('statechange', (event) => {
                if (event.target.state === 'activated') {
                    window.location.reload();
                }
            })
            waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        }

        dispatch(setServiceWorkerUpdateAvailable(null));
    }
}

export function setBeforeInstallPromptEvent(beforeinstallprompt) {
    return {
        type: SET_INSTALL_PROMPT_EVENT,
        beforeinstallprompt: beforeinstallprompt
    }
}

export function promptInstall() {
    return function(dispatch, getState) {
        const beforeinstallprompt = getState().serviceWorker.beforeinstallprompt;

        const callback = () => dispatch(setBeforeInstallPromptEvent(null));

        beforeinstallprompt
            .prompt()
            .then(callback, callback);
    }
}