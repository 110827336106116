import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Snackbar, Button } from '@material-ui/core';
import { updateServiceWorker, setBeforeInstallPromptEvent } from '../store/actions';

function ServiceWorkerHelper(props) {
    let [showReload, setShowReload] = useState(false);
    const { dispatch } = props;

    useEffect(() => {
        if (!props.serviceWorker.waitingWorker) return;

        setShowReload(true);
    }, [props.serviceWorker.waitingWorker]);

    useEffect(() =>{
        const callback = (e) => {
            e.preventDefault();
            dispatch(setBeforeInstallPromptEvent(e));
        }

        window.addEventListener("beforeinstallprompt", callback)

        return () => {
            window.removeEventListener("beforeinstallprompt", callback);
        }

    }, [dispatch]);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={showReload}
            onClose={() => setShowReload(false)}
            autoHideDuration={7000}
            message="Update available!"
            action={
                <Button color="inherit" size="small" onClick={() => props.dispatch(updateServiceWorker())}>
                    Update Now
                </Button>
              }
        />
    );
}

export default connect(
    (state) => ({serviceWorker: state.serviceWorker})
)(ServiceWorkerHelper);