
const charMap = [
    "",		//U+0900	ऀ	DEVANAGARI SIGN INVERTED CANDRABINDU
    "q",	//U+0901	ँ	DEVANAGARI SIGN CANDRABINDU
    "q",	//U+0902	ं	DEVANAGARI SIGN ANUSVARA
    "",		//U+0903	ः	DEVANAGARI SIGN VISARGA
    "E",	//U+0904	ऄ	DEVANAGARI LETTER SHORT A
    "A",	//U+0905	अ	DEVANAGARI LETTER A
    "A",	//U+0906	आ	DEVANAGARI LETTER AA
    "I",	//U+0907	इ	DEVANAGARI LETTER I
    "I",	//U+0908	ई	DEVANAGARI LETTER II
    "U",	//U+0909	उ	DEVANAGARI LETTER U
    "U",	//U+090A	ऊ	DEVANAGARI LETTER UU
    "Ru",	//U+090B	ऋ	DEVANAGARI LETTER VOCALIC R
    "L",	//U+090C	ऌ	DEVANAGARI LETTER VOCALIC L
    "E",	//U+090D	ऍ	DEVANAGARI LETTER CANDRA E
    "E",	//U+090E	ऎ	DEVANAGARI LETTER SHORT E
    "E",	//U+090F	ए	DEVANAGARI LETTER E
    "E",	//U+0910	ऐ	DEVANAGARI LETTER AI
    "O",	//U+0911	ऑ	DEVANAGARI LETTER CANDRA O
    "O",	//U+0912	ऒ	DEVANAGARI LETTER SHORT O
    "O",	//U+0913	ओ	DEVANAGARI LETTER O
    "O",	//U+0914	औ	DEVANAGARI LETTER AU
    "k",	//U+0915	क	DEVANAGARI LETTER KA
    "k`",	//U+0916	ख	DEVANAGARI LETTER KHA
    "g",	//U+0917	ग	DEVANAGARI LETTER GA
    "g`",	//U+0918	घ	DEVANAGARI LETTER GHA
    "n",	//U+0919	ङ	DEVANAGARI LETTER NGA
    "c",	//U+091A	च	DEVANAGARI LETTER CA
    "C",	//U+091B	छ	DEVANAGARI LETTER CHA
    "j",	//U+091C	ज	DEVANAGARI LETTER JA
    "j`",	//U+091D	झ	DEVANAGARI LETTER JHA
    "n",	//U+091E	ञ	DEVANAGARI LETTER NYA
    "T",	//U+091F	ट	DEVANAGARI LETTER TTA
    "T`",	//U+0920	ठ	DEVANAGARI LETTER TTHA
    "D",	//U+0921	ड	DEVANAGARI LETTER DDA
    "D`",	//U+0922	ढ	DEVANAGARI LETTER DDHA
    "N",	//U+0923	ण	DEVANAGARI LETTER NNA
    "t",	//U+0924	त	DEVANAGARI LETTER TA
    "t`",	//U+0925	थ	DEVANAGARI LETTER THA
    "d",	//U+0926	द	DEVANAGARI LETTER DA
    "d`",	//U+0927	ध	DEVANAGARI LETTER DHA
    "n",	//U+0928	न	DEVANAGARI LETTER NA
    "n",	//U+0929	ऩ	DEVANAGARI LETTER NNNA
    "p",	//U+092A	प	DEVANAGARI LETTER PA
    "p`",	//U+092B	फ	DEVANAGARI LETTER PHA
    "b",	//U+092C	ब	DEVANAGARI LETTER BA
    "b`",	//U+092D	भ	DEVANAGARI LETTER BHA
    "m",	//U+092E	म	DEVANAGARI LETTER MA
    "y",	//U+092F	य	DEVANAGARI LETTER YA
    "r",	//U+0930	र	DEVANAGARI LETTER RA
    "r",	//U+0931	ऱ	DEVANAGARI LETTER RRA
    "l",	//U+0932	ल	DEVANAGARI LETTER LA
    "L",	//U+0933	ळ	DEVANAGARI LETTER LLA
    "L",	//U+0934	ऴ	DEVANAGARI LETTER LLLA
    "v",	//U+0935	व	DEVANAGARI LETTER VA
    "S",	//U+0936	श	DEVANAGARI LETTER SHA
    "S",	//U+0937	ष	DEVANAGARI LETTER SSA
    "s",	//U+0938	स	DEVANAGARI LETTER SA
    "h",	//U+0939	ह	DEVANAGARI LETTER HA
    "",		//U+093A	ऺ	DEVANAGARI VOWEL SIGN OE
    "",		//U+093B	ऻ	DEVANAGARI VOWEL SIGN OOE
    "",		//U+093C	़	DEVANAGARI SIGN NUKTA
    "",		//U+093D	ऽ	DEVANAGARI SIGN AVAGRAHA
    "a",	//U+093E	ा	DEVANAGARI VOWEL SIGN AA
    "i",	//U+093F	ि	DEVANAGARI VOWEL SIGN I
    "i",	//U+0940	ी	DEVANAGARI VOWEL SIGN II
    "u",	//U+0941	ु	DEVANAGARI VOWEL SIGN U
    "u",	//U+0942	ू	DEVANAGARI VOWEL SIGN UU
    "Ru",	//U+0943	ृ	DEVANAGARI VOWEL SIGN VOCALIC R
    "Ru",	//U+0944	ॄ	DEVANAGARI VOWEL SIGN VOCALIC RR
    "e",	//U+0945	ॅ	DEVANAGARI VOWEL SIGN CANDRA E
    "e",	//U+0946	ॆ	DEVANAGARI VOWEL SIGN SHORT E
    "e",	//U+0947	े	DEVANAGARI VOWEL SIGN E
    "e",	//U+0948	ै	DEVANAGARI VOWEL SIGN AI
    "o",	//U+0949	ॉ	DEVANAGARI VOWEL SIGN CANDRA O
    "o",	//U+094A	ॊ	DEVANAGARI VOWEL SIGN SHORT O
    "o",	//U+094B	ो	DEVANAGARI VOWEL SIGN O
    "o",	//U+094C	ौ	DEVANAGARI VOWEL SIGN AU
    "f",	//U+094D	्	DEVANAGARI SIGN VIRAMA
    "",		//U+094E	ॎ	DEVANAGARI VOWEL SIGN PRISHTHAMATRA E
    "o",	//U+094F	ॏ	DEVANAGARI VOWEL SIGN AW
    "OM",	//U+0950	ॐ	DEVANAGARI OM
    "",		//U+0951	॑	DEVANAGARI STRESS SIGN UDATTA
    "",		//U+0952	॒	DEVANAGARI STRESS SIGN ANUDATTA
    "",		//U+0953	॓	DEVANAGARI GRAVE ACCENT
    "",		//U+0954	॔	DEVANAGARI ACUTE ACCENT
    "e",	//U+0955	ॕ	DEVANAGARI VOWEL SIGN CANDRA LONG E
    "",		//U+0956	ॖ	DEVANAGARI VOWEL SIGN UE
    "",		//U+0957	ॗ	DEVANAGARI VOWEL SIGN UUE
    "",		//U+0958	क़	DEVANAGARI LETTER QA
    "",		//U+0959	ख़	DEVANAGARI LETTER KHHA
    "",		//U+095A	ग़	DEVANAGARI LETTER GHHA
    "",		//U+095B	ज़	DEVANAGARI LETTER ZA
    "",		//U+095C	ड़	DEVANAGARI LETTER DDDHA
    "",		//U+095D	ढ़	DEVANAGARI LETTER RHA
    "",		//U+095E	फ़	DEVANAGARI LETTER FA
    "",		//U+095F	य़	DEVANAGARI LETTER YYA
    "Ru",	//U+0960	ॠ	DEVANAGARI LETTER VOCALIC RR
    "L",	//U+0961	ॡ	DEVANAGARI LETTER VOCALIC LL
    "L",	//U+0962	ॢ	DEVANAGARI VOWEL SIGN VOCALIC L
    "L",	//U+0963	ॣ	DEVANAGARI VOWEL SIGN VOCALIC LL
    "",		//U+0964	।	DEVANAGARI DANDA
    "",		//U+0965	॥	DEVANAGARI DOUBLE DANDA
    "",		//U+0966	०	DEVANAGARI DIGIT ZERO
    "",		//U+0967	१	DEVANAGARI DIGIT ONE
    "",		//U+0968	२	DEVANAGARI DIGIT TWO
    "",		//U+0969	३	DEVANAGARI DIGIT THREE
    "",		//U+096A	४	DEVANAGARI DIGIT FOUR
    "",		//U+096B	५	DEVANAGARI DIGIT FIVE
    "",		//U+096C	६	DEVANAGARI DIGIT SIX
    "",		//U+096D	७	DEVANAGARI DIGIT SEVEN
    "",		//U+096E	८	DEVANAGARI DIGIT EIGHT
    "",		//U+096F	९	DEVANAGARI DIGIT NINE
    "",		//U+0970	॰	DEVANAGARI ABBREVIATION SIGN
    "",		//U+0971	ॱ	DEVANAGARI SIGN HIGH SPACING DOT
    "A",	//U+0972	ॲ	DEVANAGARI LETTER CANDRA A
    "",		//U+0973	ॳ	DEVANAGARI LETTER OE
    "",		//U+0974	ॴ	DEVANAGARI LETTER OOE
    "O",	//U+0975	ॵ	DEVANAGARI LETTER AW
    "",		//U+0976	ॶ	DEVANAGARI LETTER UE
    "",		//U+0977	ॷ	DEVANAGARI LETTER UUE
    "",		//U+0978	ॸ	DEVANAGARI LETTER MARWARI DDA
    "j`",	//U+0979	ॹ	DEVANAGARI LETTER ZHA
    "y",	//U+097A	ॺ	DEVANAGARI LETTER HEAVY YA
    "g",	//U+097B	ॻ	DEVANAGARI LETTER GGA
    "j",	//U+097C	ॼ	DEVANAGARI LETTER JJA
    "",		//U+097D	ॽ	DEVANAGARI LETTER GLOTTAL STOP
    "D",	//U+097E	ॾ	DEVANAGARI LETTER DDDA
    "b",	//U+097F	ॿ	DEVANAGARI LETTER BBA
];


const unicodeBlocks = {
    deva: [0x0900, 0x097F],
    beng: [0x0980, 0x09FF],
    guru: [0x0A00, 0x0A7F],
    gujr: [0x0A80, 0x0AFF],
    orya: [0x0B00, 0x0B7F],
    taml: [0x0B80, 0x0BFF],
    telu: [0x0C00, 0x0C7F],
    knda: [0x0C80, 0x0CFF],
    mlym: [0x0D00, 0x0D7F],
}

function getScriptNeutralCodePoint(char) {
    const charCode = char.codePointAt(0);

    for (const script in unicodeBlocks) {
        const [start, end] = unicodeBlocks[script];
        if (charCode >= start && charCode <= end) {
            return charCode - start;
        }
    }

    return -1;
}

export function encodeIndic(str) {
    str = str.normalize();

    // 1st encoding removes the information we don't want in same script exact match.
    // For example, after the encoding, there is no distiction between इ/ई, उ/ऊ, ऑ/ओ/औ etc.
    let encoding1 = "";

    for (const char of [...str]) {
        const scriptNeutralCodePoint = getScriptNeutralCodePoint(char);

        if (scriptNeutralCodePoint >= 0) {
            encoding1 += charMap[scriptNeutralCodePoint];
        } else {
            encoding1 += char;
        }
    }

    encoding1 = encoding1
        .replace(/[mn]f/g, "q")  // Convert to anuswara

    // 2nd encoding removes some information which is not much relavent in transliterated search from Latin
    // For example, after the encoding, there is no distiction between त/ट, थ/ठ etc.
    // This also removes halant, anuswara and a to get high recall.
    let encoding2 = "I" + encoding1
                            .toLowerCase()
                            .replace(/f/g, "")  // Remove halant
                            .replace(/q/g, "")  // Remove anuswara
                            .replace(/([^^])a+/g, "$1");  // Remove a in the middle

    return [encoding1, encoding2];
}
