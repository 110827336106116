import React from 'react';
import { connect } from 'react-redux';
import { Link } from "@reach/router";
import { Divider, List, ListItem, ListItemText, ListItemIcon, ListSubheader, Box } from '@material-ui/core'
import { LibraryBooks, Home, Settings, Update, GetApp } from '@material-ui/icons';
import TransliteratedText from './TransliteratedText';

import { updateServiceWorker, promptInstall } from '../store/actions';

function AppDrawerContent (props) {
    return (
        <div style={{minWidth: 250}}>
            <div style={{height: 50}}></div>
            <List>
                <ListItem button component={Link} to="/" key="home" onClick={props.closeDrawer}>
                    <ListItemIcon>
                        <Home />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                <Divider />
                <ListSubheader>
                    Collections
                </ListSubheader>
                {
                    Object.entries(props.collections).map(([collectionId, {title}]) => (
                        <ListItem button component={Link} to={`/c/${collectionId}`} key={collectionId} onClick={props.closeDrawer}>
                            <ListItemIcon>
                                <LibraryBooks />
                            </ListItemIcon>
                            <ListItemText>
                                <TransliteratedText>{title}</TransliteratedText>
                            </ListItemText>
                        </ListItem>
                    ))
                }
                <Divider />
                <ListItem button component={Link} to="/settings" key="settings" onClick={props.closeDrawer}>
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItem>
                { (props.serviceWorker.waitingWorker || props.serviceWorker.beforeinstallprompt) && (
                    <Box paddingY={1}></Box>
                )}
                { props.serviceWorker.waitingWorker && (
                    <ListItem button key="update" onClick={() => {
                        props.dispatch(updateServiceWorker());
                    }}>
                        <ListItemIcon>
                            <Update />
                        </ListItemIcon>
                        <ListItemText primary="Update Now" />
                    </ListItem>
                )}
                { props.serviceWorker.beforeinstallprompt && (
                    <ListItem button key="install" onClick={() => {
                        props.dispatch(promptInstall());
                    }}>
                        <ListItemIcon>
                            <GetApp />
                        </ListItemIcon>
                        <ListItemText primary="Install App" />
                    </ListItem>
                )}
            </List>
        </div>
    );
}

export default connect(
    (state) => ({collections: state.collections, serviceWorker: state.serviceWorker})
)(AppDrawerContent);