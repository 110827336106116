import { useCallback, useEffect, useRef } from 'react';

export default function WakeLock() {
    let wakeLock = useRef(null);

    const requestWakeLock = useCallback(() => {
        navigator.wakeLock.request('screen')
            .then((_wakeLock) => {
                _wakeLock.addEventListener('release', () => {
                    wakeLock.current = null;
                    console.log('Screen Wake Lock was released');
                });
                wakeLock.current = _wakeLock;
                console.log('Screen Wake Lock is active');
            })
            .catch((err) => {
                console.error(`${err.name}, ${err.message}`);
            });
    }, [wakeLock]);

    const disableWakeLock = useCallback(() => {
        console.log("Disabled Screen Wake Lock");
        if (wakeLock.current !== null) {
            wakeLock.current.release();
        }
    }, [wakeLock]);

    const handleVisibilityChange = useCallback(() => {
        if (wakeLock.current === null && document.visibilityState === "visible") {
            requestWakeLock();
        }
    }, [wakeLock, requestWakeLock]);

    useEffect(() => {
        if (!("wakeLock" in navigator && "request" in navigator.wakeLock)) return;

        console.log("Enabled Screen Wake Lock");

        document.addEventListener("visibilitychange", handleVisibilityChange);
        document.addEventListener("fullscreenchange", handleVisibilityChange);

        requestWakeLock();

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            document.removeEventListener("fullscreenchange", handleVisibilityChange);

            disableWakeLock();
        };
    }, [handleVisibilityChange, requestWakeLock, disableWakeLock]);

    return null;
}
