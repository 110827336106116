import { SET_WAITING_SERVICE_WORKER, SET_INSTALL_PROMPT_EVENT } from '../actions.js';

function serviceWorkerReducer (prevState = {waitingWorker: null}, action) {
    switch (action.type) {
        case SET_WAITING_SERVICE_WORKER:
            return {
                ...prevState,
                waitingWorker: action.waitingWorker
            }
        case SET_INSTALL_PROMPT_EVENT:
            return {
                ...prevState,
                beforeinstallprompt: action.beforeinstallprompt
            }
        default:
            return prevState
    }
}

export default serviceWorkerReducer;
