import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router';
import { Container, Typography, Box, Link as MaterialLink } from '@material-ui/core';
import GoogleAnalytics from './GoogleAnalytics';

function NotFound(props) {
    return (
        <Container maxWidth="sm">
            <Helmet>
                <title>404 - Page not Found</title>
            </Helmet>
            <GoogleAnalytics uri={props.uri} title={"404 - Page not Found"} />
            <Box paddingY={4} paddingX={2}>
                <Typography variant="h5" component="div">
                    404
                </Typography>
                <Typography variant="h6" component="div">
                    Page not found.
                </Typography>
                <br />
                <br />
                <Typography variant="body1">
                    <MaterialLink to="/" component={Link} color="secondary">Go to Home</MaterialLink> or <MaterialLink to="/search" component={Link} color="secondary">try search?</MaterialLink>
                </Typography>
            </Box>
        </Container>
    );
}

export default NotFound;