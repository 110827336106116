import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from "@reach/router";
import { Container, Select, InputAdornment, MenuItem, Box, Typography, Fab, Zoom, Dialog, DialogTitle, List, ListItem, ListItemText, ListItemIcon, Snackbar } from '@material-ui/core';
import { Translate, ShareOutlined, Link, MailOutline, WhatsApp, FileCopyOutlined } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from './GoogleAnalytics';
import TransliteratedText from './TransliteratedText';
import NotFound from './NotFound';
import WakeLock from './WakeLock';

const supportedScripts = ["gujr", "deva"]

const availableScripts = {
    gujr: "ગુજરાતી",
    deva: "देवनागरी",
    qaab: "Dēvanāgarī",
}

function ScriptSelector(props) {
    const theme = useTheme();

    return (
        <Box marginTop={1} marginBottom={4} display="inline-block" position="sticky" left={theme.spacing(2)}>
            <Select
                onChange={(event) => props.onChange(event.target.value)}
                value={props.selectedScript}
                startAdornment={
                    <InputAdornment position="start">
                        <Translate style={{fontSize: '1.2rem'}} />
                    </InputAdornment>
                }
                disabled={props.unsupported}
                inputProps={{"aria-label": "Select Script"}}
                >
                {
                    props.unsupported && (
                        <MenuItem value={props.selectedScript} key={props.selectedScript}>Original</MenuItem>
                    )
                }
                {
                    Object.keys(availableScripts).map((script) => (
                        <MenuItem value={script} key={script}>{availableScripts[script] + (script === props.originalScript ? " - Original" : "")}</MenuItem>
                    ))
                }
            </Select>
        </Box>
    )
}

function ShareFAB(props) {
    const theme = useTheme();
    const location = useLocation();

    const [shown, setShown] = useState(false);
    const [copiedSnackbarShown, setCopiedSnackbarShown] = useState(false);

    let isAndroid = false;
    let isCopySupported = false;
    if (typeof navigator !== "undefined") {
        isAndroid = /(android)/i.test(navigator.userAgent);
        isCopySupported = navigator?.clipboard?.writeText;
    }

    function getShareContent() {
        let title = window?.title;
        let text = props.getContent() + "\n";
        let url = location.href;
        let humanUrl = decodeURI(url);
        let fullText = `${text}\n\n${humanUrl}`;

        return { title, text, url, humanUrl, fullText };
    }

    function openShare() {
        if (navigator?.share) {
            let {title, text, humanUrl} = getShareContent();
            navigator.share({
                title: title,
                text: text,
                url: humanUrl,
            })
            .catch(console.error);
        } else {
            setShown(true);
        }
    }

    function shareUsing(method) {
        switch (method) {
            case 'copyLink': {
                if (isCopySupported) {
                    navigator.clipboard.writeText(getShareContent().humanUrl);
                    setCopiedSnackbarShown(true);
                }
                break;
            }
            case 'copy': {
                if (isCopySupported) {
                    navigator.clipboard.writeText(getShareContent().fullText);
                    setCopiedSnackbarShown(true);
                }
                break;
            }
            case 'whatsapp': {
                if (window) {
                    let href = `whatsapp://send?text=${encodeURIComponent(getShareContent().fullText)}`;
                    window.open(href);
                }
                break;
            }
            case 'mail': {
                if (window) {
                    let body = getShareContent().fullText.replace("\n", "\r\n");
                    let href = `mailto:?subject=${encodeURIComponent("You may like this")}&body=${encodeURIComponent(body)}`;
                    window.open(href);
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    return (
        <React.Fragment>
            <Zoom in={!shown}>
                <Fab
                    color="secondary"
                    style={{position: 'fixed', right: theme.spacing(4), bottom: theme.spacing(4)}}
                    onClick={openShare}
                    aria-label="Share"
                >
                    <ShareOutlined />
                </Fab>
            </Zoom>
            <Dialog open={shown} onClose={() => setShown(false)}>
                <DialogTitle>Share</DialogTitle>
                <List>
                    { isCopySupported && (
                        <ListItem button onClick={() => shareUsing('copyLink')}>
                            <ListItemIcon><Link /></ListItemIcon>
                            <ListItemText>Copy Link</ListItemText>
                        </ListItem>
                    )}
                    { isCopySupported && (
                        <ListItem button onClick={() => shareUsing('copy')}>
                            <ListItemIcon><FileCopyOutlined /></ListItemIcon>
                            <ListItemText>Copy Text and Link</ListItemText>
                        </ListItem>
                    )}
                    { isAndroid && (
                        <ListItem button onClick={() => shareUsing('whatsapp')}>
                            <ListItemIcon><WhatsApp /></ListItemIcon>
                            <ListItemText>WhatsApp</ListItemText>
                        </ListItem>
                    )}
                    <ListItem button onClick={() => shareUsing('mail')}>
                        <ListItemIcon><MailOutline /></ListItemIcon>
                        <ListItemText>E-mail</ListItemText>
                    </ListItem>
                </List>
            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={copiedSnackbarShown}
                onClose={() => setCopiedSnackbarShown(false)}
                autoHideDuration={3000}
                message="Copied!"
            />
        </React.Fragment>
    );
}

function Document(props) {
    const theme = useTheme();
    const document = props.collections[props.collectionId]?.docs[props.documentId];

    const scriptUnsupported = supportedScripts.indexOf(document?.script) < 0;

    let [script, setScript] = useState(
        (scriptUnsupported || props.settings.scripts.indexOf(document?.script) >= 0) ?
            document?.script : props.settings.scripts[0],
    );

    const content = useRef();

    if (!document) return <NotFound />;

    return (
        <Container>
            <Helmet>
                <title>{document.title}</title>
            </Helmet>
            <GoogleAnalytics uri={props.uri} title={document.title} />
            <WakeLock />
            <ScriptSelector originalScript={document.script} selectedScript={script} onChange={setScript} unsupported={scriptUnsupported} />
            <Typography component="pre" style={{width: "max-content", paddingRight: theme.spacing(2), paddingBottom: theme.spacing(8), userSelect: "text"}} ref={content}><TransliteratedText from={document.script} to={script}>{document.content}</TransliteratedText></Typography>
            <ShareFAB getContent={() => content.current.textContent} />
        </Container>
    );
}

export default connect(
    (state) => ({collections: state.collections, settings: state.settings})
)(Document);