import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Container, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Checkbox, FormControl, FormLabel, Box, Switch, Divider } from '@material-ui/core';
import { DragHandle as DragHandleIcon } from '@material-ui/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import GoogleAnalytics from './GoogleAnalytics';

import { setPreferredScripts, setAdminIterface } from '../store/actions';


const availableScripts = {
    deva: "देवनागरी",
    gujr: "ગુજરાતી",
    qaab: "Dēvanāgarī",
}

const DragHandle = SortableHandle(() => (
    <ListItemIcon>
        <DragHandleIcon />
    </ListItemIcon>
));

function SingleScript({id, text, checked, onCheckedChange, checkboxDisabled}) {
    return (
        <ListItem ContainerComponent="div">
            {
                checked &&  (
                    <DragHandle />
                )
            }
            <ListItemText inset={!checked} primaryTypographyProps={{component: "label", id}} primary={text} />
            <ListItemSecondaryAction>
                <Checkbox edge="end" checked={checked} onChange={onCheckedChange} disabled={checkboxDisabled} inputProps={{"aria-labelledby": id}} />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

const SortableSingleScript = SortableElement(SingleScript);

function ListContainer({ items, onCheckedChange, ItemElement, style }) {
    return (
        <List component="div" style={style}>
            {items.map((item, index) => (
                <ItemElement
                    key={item.script}
                    id={item.script}
                    index={index}
                    text={item.text}
                    checked={item.checked}
                    onCheckedChange={(event) => onCheckedChange(item.script, event.target.checked)}
                    checkboxDisabled={item.disabled} />
            ))}
        </List>
    );
}

const SortableListContainer = SortableContainer(ListContainer);

const SortableScriptsList = ({scripts, setScripts}) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        let newScripts = [...scripts];
        let item = newScripts.splice(oldIndex, 1)[0];
        newScripts.splice(newIndex, 0, item);

        setScripts(newScripts);
    };

    const onCheckedChange = (script, checked) => {
        if (checked && scripts.indexOf(script) < 0) {
            let newScripts = [...scripts];
            newScripts.push(script);
            setScripts(newScripts);
        } else if (!checked) {
            let index = scripts.indexOf(script);
            if (index >= 0) {
                let newScripts = [...scripts];
                newScripts.splice(index, 1);
                setScripts(newScripts);
            }
        }
    }

    let selectedScripts = scripts.map((script) => ({
        script,
        text: availableScripts[script],
        checked: true,
        disabled: scripts.length > 1 ? false : true,
    }));

    let otherScripts = [];
    for (let script in availableScripts) {
        if (scripts.indexOf(script) < 0) {
            otherScripts.push({
                script,
                text: availableScripts[script],
                checked: false,
                disabled: false,
            });
        }
    }

    return (
        <Box paddingY={2}>
            <FormControl component="fieldset" focused={false} style={{display: "block"}}>
                <FormLabel component="legend">Which scripts do you read? In order of preference.</FormLabel>
                <SortableListContainer
                    items={selectedScripts}
                    onSortEnd={onSortEnd}
                    useDragHandle={true}
                    lockAxis="y"
                    onCheckedChange={onCheckedChange}
                    ItemElement={SortableSingleScript}
                    style={{paddingBottom: 0}}
                />
                <ListContainer
                    items={otherScripts}
                    onCheckedChange={onCheckedChange}
                    ItemElement={SingleScript}
                    style={{paddingTop: 0}}
                />
            </FormControl>
        </Box>
    );
};

export function AdminInterface (props) {
    return (
        <Box paddingY={2}>
            <ListItem ContainerComponent="div">
                <ListItemText primaryTypographyProps={{component: "label", id: "admin-interface"}} primary="Show admin interface" />
                <ListItemSecondaryAction>
                    <Switch edge="end" checked={props.checked} onChange={props.onChange} inputProps={{"aria-labelledby": "admin-interface"}} />
                </ListItemSecondaryAction>
            </ListItem>
        </Box>
    )
}

function Settings(props) {
    return (
        <Container maxWidth="sm">
            <Helmet>
                <title>Settings</title>
            </Helmet>
            <GoogleAnalytics uri={props.uri} title={"Settings"} />
            <SortableScriptsList scripts={props.settings.scripts} setScripts={(scripts) => props.dispatch(setPreferredScripts(scripts))} />
            <Divider />
            { props.settings.admin && <AdminInterface checked={props.settings.admin} onChange={(event) => props.dispatch(setAdminIterface(event.target.checked))} />}
        </Container>
    );
}

export default connect(
    (state) => ({settings: state.settings})
)(Settings);