import levenshtein from 'js-levenshtein';
import { detect } from 'lipi';

import { encodeLatinForIndex, encodeLatinForSearch } from './encodeLatin';
import { encodeIndic } from './encodeIndic';

class IntertedIndex {
    constructor() {
        this.root = {}
    }

    export() {
        return this.root;
    }

    import(invertedIndex) {
        this.root = invertedIndex;
    }

    findOrAddDefault(key, defaultValue) {
        const keys = [...key];

        let node = this.root;
        for (const k of keys) {
            node[k] = node[k] || {};
            node = node[k];
        }

        if (!('value' in node)) {
            node.value = defaultValue;
        }

        return node.value;
    }

    findNode(key) {
        const keys = [...key];

        let node = this.root;
        for (const k of keys) {
            if (k in node) {
                node = node[k];
            } else {
                return {};
            }
        }

        return node;
    }

    getNextLevel(nodes) {
        const values = [];
        const nextNodes = [];

        for (const node of nodes) {
            for (const k in node) {
                if (k === 'value') {
                    values.push(node.value);
                } else {
                    nextNodes.push(node[k]);
                }
            }
        }

        return {values, nextNodes};
    }
}

class SearchIndic {
    constructor() {
        this.invertedIndex = new IntertedIndex();
        this.invertedIndexMetadata = {
            countTokens: 0,
            countDocuments: 0
        };
    }

    addDocument(documentId, content) {
        let tokenIndex = 0;
        for (const match of content.matchAll(/[\p{L}\p{M}]+/gu)) {
            const [enc1, enc2] = this._encodeIndexToken(match[0]);

            let enc2node = this.invertedIndex.findOrAddDefault(enc2, {});

            enc2node[enc1] = enc2node[enc1] || {};
            enc2node[enc1][documentId] = enc2node[enc1][documentId] || [];

            enc2node[enc1][documentId].push({
                token: match[0],
                charIndex: match.index,
                tokenIndex: tokenIndex
            });

            tokenIndex++;
            this.invertedIndexMetadata['countTokens']++;
        }

        this.invertedIndexMetadata['countDocuments']++;
    }

    _encodeIndexToken(token) {
        if (detect(token) === "qaaa") {
            return encodeLatinForIndex(token);
        } else {
            return encodeIndic(token);
        }
    }

    _encodeQueryToken(queryToken) {
        if (detect(queryToken) === "qaaa") {
            return encodeLatinForSearch(queryToken);
        } else {
            return [{
                enc: encodeIndic(queryToken),
                score: 1,
                similarityMethod: "indic",
            }];
        }
    }

    _similarity(encA, encB, method) {
        if (method === "latin") {
            encA = encA.toLowerCase();
            encB = encB.toLowerCase();
        }

        return (1 - (levenshtein(encA, encB) / Math.max(encA.length, encB.length)));
    }

    search(query) {
        const queryTokens = query.match(/[\p{L}\p{M}]+/gu);

        if (!queryTokens || !queryTokens.length) {
            return;
        }

        let results = {};

        const encodedQueryTokens = queryTokens.map(this._encodeQueryToken.bind(this));

        // Retrieval
        let reshapedQueryTokens = {};
        for (const [i, possibleEncs] of encodedQueryTokens.entries()) {
            reshapedQueryTokens[i] = reshapedQueryTokens[i] || {};
            for (const {enc: [enc1, enc2], score, similarityMethod} of possibleEncs) {
                let enc2node = this.invertedIndex.findNode(enc2);

                if (!enc2node) continue;

                reshapedQueryTokens[i][enc1] = {
                    enc2nodes: [enc2node],
                    score,
                    similarityMethod
                }
            }
        }

        let didChange = true;
        while (Object.keys(results).length < 20 && didChange) {
            didChange = false;

            for (const i in reshapedQueryTokens) {
                for (const enc1 in reshapedQueryTokens[i]) {
                    let { enc2nodes, score, similarityMethod } = reshapedQueryTokens[i][enc1];

                    if (!enc2nodes.length) continue;
                    didChange = true;

                    const { values, nextNodes } = this.invertedIndex.getNextLevel(enc2nodes);

                    for (const enc2value of values) {
                        // For all encoding1, add document to results
                        for (const enc in enc2value) {
                            for (const doc in enc2value[enc]) {
                                results[doc] = results[doc] || {
                                    matches: {}
                                };

                                results[doc].matches[i] = results[doc].matches[i] || {}

                                if (!(enc in results[doc].matches[i])) {
                                    results[doc].matches[i][enc] = enc2value[enc][doc].map(match => {
                                        const similarity = score * this._similarity(enc, enc1, similarityMethod);

                                        const idf = Math.log(this.invertedIndexMetadata.countDocuments / Object.keys(enc2value[enc]).length);
                                        const tf = Math.log(1 + enc2value[enc][doc].length);
                                        const pos = Math.max(1 - ((enc2value[enc][doc][0].tokenIndex / 10) ** 4), 0.5); // First occurrence
                                        const relavence = tf * idf * similarity * pos;

                                        return {
                                            ...match,
                                            queryToken: {
                                                index: i,
                                                similarity,
                                                relavence,
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }

                    reshapedQueryTokens[i][enc1].enc2nodes = nextNodes;
                    reshapedQueryTokens[i][enc1].score *= 0.5;
                }
            }
        }

        // Scoring
        for (const doc in results) {
            results[doc].scores = {
                matches: 0,
                relavence: 0
            };

            for (const i in results[doc].matches) {
                // for (const enc in results[doc].matches[i]) {

                const {maxSimilarity, maxRelavence} =
                    Object.values(results[doc].matches[i])
                        .reduce(
                            ({maxSimilarity, maxRelavence}, matches) => {
                                const {similarity, relavence} = matches.reduce(({similarity, relavence}, match) => ({
                                    similarity: Math.max(match.queryToken.similarity, similarity),
                                    relavence: Math.max(match.queryToken.relavence, relavence),
                                }), {similarity: 0, relavence: 0});

                                return {
                                    maxSimilarity: Math.max(similarity, maxSimilarity),
                                    maxRelavence: Math.max(relavence, maxRelavence),
                                }
                            },
                            {maxSimilarity: 0, maxRelavence: 0}
                        );

                results[doc].scores.matches += maxSimilarity;
                results[doc].scores.relavence += maxRelavence;
            }
        }

        // Ranking
        let resultsArray = []
        for (const doc in results) {
            resultsArray.push({
                docId: doc,
                result: results[doc]
            });
        }

        resultsArray.sort((a ,b) => {
            if (a.result.scores.matches !== b.result.scores.matches) {
                return b.result.scores.matches - a.result.scores.matches;
            }

            return b.result.scores.relavence - a.result.scores.relavence;
        })

        return resultsArray;
    }

    export() {
        return {
            invertedIndex: this.invertedIndex.export(),
            invertedIndexMetadata: this.invertedIndexMetadata,
        }
    }

    import({invertedIndex, invertedIndexMetadata}) {
        if (!invertedIndex || !invertedIndexMetadata) return;

        this.invertedIndex.import(invertedIndex);
        this.invertedIndexMetadata = invertedIndexMetadata;
    }
}

export default SearchIndic;
