import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import collections from './reducers/collections';
import settings from './reducers/settings';
import search from './reducers/search';
import serviceWorker from './reducers/serviceWorker';

function configureStore (initialState = {}) {
    let store = createStore(
        combineReducers({
            collections,
            settings,
            search,
            serviceWorker,
        }),
        initialState,
        applyMiddleware(thunk)
    );

    let persistor = persistStore(store);

    return {store, persistor};
}

export default configureStore;