import { SET_PREFERRED_SCRIPTS, SET_ADMIN_INTERFACE } from '../actions.js';
import { persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const defaultSettings = {
    scripts: ["gujr", "deva", "qaab"],
    admin: false,
}

const migrations = {
    0: prevState => prevState,
    1: prevState => ({
        ...prevState,
        admin: false
    }),
    2: prevState => ({
        ...prevState,
        scripts: [...(new Set(prevState.scripts).add("qaab"))]
    })
}

const persistConfig = {
    key: 'settings',
    version: 2,
    storage,
    migrate: createMigrate(migrations),
}

function settingsReducer (prevState = defaultSettings, action) {
    switch (action.type) {
        case SET_PREFERRED_SCRIPTS:
            return {
                ...prevState,
                scripts: action.scripts
            }
            case SET_ADMIN_INTERFACE:
            return {
                ...prevState,
                admin: action.admin
            }
        default:
            return prevState
    }
}

export default persistReducer(persistConfig, settingsReducer);
